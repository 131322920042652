import React, { useState,useRef } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import PopuVideo from "../components/PopuVideo"
import Button from "../components/styles/Button"


export default function CaseList({ pageContext: { node,list },location }) {
    const [headerColor,setHeaderColor] = useState("bg-transparent text-white")
    const refList = useRef(null)

    const showMore = (e)=>{
        let listDom = refList.current.querySelectorAll(".hidden")
        const listCount = 6
        for(let i=0;i<listDom.length;i++){
            if(i<listCount){
                listDom[i].classList.remove("hidden")
            }
        }
        if(listDom.length<=listCount){
            e.target.classList.add("hidden")
        }
    }

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="relative h-screen w-screen overflow-hidden">
                <PopuVideo video={node.video.link} bg={node.banner.localFile}/>
            </div>
            
            <div className="container mx-auto pt-[193px] pb-[140px] sm:py-20">
                <div className="flex justify-between items-end pb-[58px] sm:flex-col sm:items-start">
                    <h1 className="text-[40px] leading-snug sm:mb-8">案例应用</h1>
                    <ul className="flex items-center text-gray flex-wrap sm:justify-center sm:w-full">
                    {
                        list.map((n,i,a)=>{
                            return (
                                <li key={i} className={`${i < a.length-1?'mr-[38px] sm:mr-5':''}`}><a className={`${n.url==node.url?'text-black':'hover:text-black'}`} href={n.url}>{n.title}</a></li>
                            )
                        })
                    }
                    </ul>
                </div>
                
                <div className="grid grid-cols-3 gap-x-[60px] gap-y-[100px] xl:grid-cols-2 md:grid-cols-1 sm:gap-y-20" ref={refList}>
                {
                    node.case_details.map((n,i)=>{
                        return (
                            <div key={i} className={`bg-white ${i<6?'':'hidden'}`}>
                                <figure className="h-[320px] overflow-hidden"><GatsbyImage className="w-full h-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(n.casePicture.localFile)} alt="article picture"/></figure>
                                <h2 className="text-3xl pt-2.5 pb-10 leading-snug">{n.title}</h2>
                                <Button variant="black" href={n.url}>了解更多</Button>
                            </div>
                        )
                    })
                }
                </div>
                <div onClick={showMore} className={`w-[240px] h-10 rounded-full bg-[#ededed] hover:bg-black hover:text-white flex justify-center items-center mx-auto mt-[82px] sm:mt-8 hover:cursor-pointer ${node.case_details.length>6?'':'hidden'}`}>显示接下来6个</div>
            </div>
        </Layout>
    )
}

